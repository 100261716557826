import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'MODA FC',
    useCase: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, APPAREL DESIGN',
    description: `MODA FC was a personal brand that I developed with a friend who unfortunately is no longer with us. It was inspired by our love for soccer and how it has impacted so many lives around the globe. We released the global assault collection in a limited run during the 2014 World Cup. I designed each country’s shirt by taking items from that countries premier teams branding and mixing it with my own style. with copyrights being strict we wanted to deliver a product that would be recognizable but also different. Even though my business partner in this concept is no longer here, the spirit of his drive and passion lives on whenever I see someone wearing a MODA FC shirt around the Seattle area.

    I continue to be inspired by the beautiful game.`,
  },
  caseId: 'moda-fc',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
    'image10.jpg',
    'image11.jpg',
    'image12.jpg',
    'image13.jpg',
    'image14.jpg',
    'image15.jpg',
    'image16.jpg',
    'image17.jpg',
    'image18.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
